:root {
  --green: #4A7729;
  --gray: rgb(140, 130, 121);
  --semi-green: #4a77294d;
  --semi-green-2: #4a7729e5;
  --semi-green-3: #4a772934;
  --semi-gray: rgba(140, 130, 121, 0.2);
  --semi-gray-2: rgba(140, 130, 121, 0.1);
  --semi-gray-2-opaque: rgb(244, 243, 242);
  --semi-gray-2-semi-opaque: rgba(244, 243, 242, 0.5);
  --semi-gray-3: rgba(140, 130, 121, 0.95);

  --header-height: 70px;
  --container-padding: 20px;
  --container-width: 1200px;
}

* {
  box-sizing: border-box;
  font-weight: normal;
}

body {
  color: var(--gray);
  position: relative;
}

.container > hr {
  margin: 2em auto;
}

.container {
  max-width: var(--container-width);
  padding: 0 var(--container-padding);
  margin: 0 auto;
}

main {
  padding-top: var(--header-height);
}

b {
  color: var(--green);
}

a {
  text-decoration: none;
  color: var(--green);
}

h1 {
  color: var(--green);
}

p {
  color: var(--gray);
}

body {
  background-color: white;
}

section.container {
  margin-bottom: 2em;
}

.grayscale {
  filter: grayscale(1);
  transition: 0.4s;
}

.grayscale:hover {
  filter: grayscale(0);
}

/* Elements */

img {
  max-width: 100%;
  object-fit: contain;
  height: auto;
}